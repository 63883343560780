import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationOverlay extends MrModalDialog {
	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );
	}

	override firstFocusableElement(): HTMLElement | void {
		if ( !this.parentNode ) {
			return this;
		}

		const firstFocusableChild = this.querySelector( 'nav' );
		if ( firstFocusableChild && firstFocusableChild instanceof HTMLElement ) {
			firstFocusableChild.setAttribute( 'data-forced-focus', '' );

			const removeForcedFocus = () => {
				firstFocusableChild.removeAttribute( 'data-forced-focus' );
				firstFocusableChild.removeEventListener( 'blur', removeForcedFocus );
			};

			firstFocusableChild.addEventListener( 'blur', removeForcedFocus, {
				once: true,
			} );

			return firstFocusableChild;
		}

		return this;
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '0',
					},
					{
						opacity: '1',
					},
				],
				{
					duration: 96,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '1',
					},
					{
						opacity: '0',
					},
				],
				{
					duration: 96,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-navigation-overlay', MrNavigationOverlay );
