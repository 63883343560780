window.addEventListener( 'load', () => {
	const marqueeText = document.querySelector( '.notification-bar__text' ) as HTMLElement | null;
	const marquee = document.querySelector( '.notification-bar__content' ) as HTMLElement | null;
	const marqueeContainer = document.querySelector( '.notification-bar' ) as HTMLElement | null;

	if ( !marqueeText ) {
		return;
	}
	if ( !marqueeContainer ) {
		return;
	}
	if ( !marquee ) {
		return;
	}

	const textWidth = marqueeText.offsetWidth;
	const windowWidth = window.innerWidth;

	if ( windowWidth >= textWidth ) {
		marqueeContainer.appendChild( marqueeText );
		marquee.remove();
		marqueeContainer.classList.add( 'notification-bar--no-marquee' );
		marqueeText.classList.add( 'notification-bar__text--no-marquee' );
	}
	setTimeout( () => {
		marqueeText.style.opacity = '1';
	}, 256 );
} );
