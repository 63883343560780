import { doOncePerVisit, MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrPopup extends MrModalDialog {
	#hasRendered = false;

	override connectedCallback() {
		super.connectedCallback();

		this.getContent();
	}

	async getContent() {
		let preview = false;
		if ( this.dataset.preview && this.dataset.preview === '1' ) {
			preview = true;
		}

		const resp = await fetch( `/wp-json/popup/general.json?preview=${preview}` );
		const popupResponse = await resp.json();
		if ( !popupResponse || !popupResponse.html ) {
			return;
		}

		// Only open popup when no other overlays are opened.
		if ( document.body.classList.contains( 'is-showing-modal-dialog' ) ) {
			return;
		}

		const popup_content = this.querySelector( 'div[data-popup-content]' );

		if ( !popup_content ) {
			return;
		}

		setTimeout( () => {
			// Only open popup when no other overlays are opened.
			if ( document.body.classList.contains( 'is-showing-modal-dialog' ) ) {
				return;
			}

			let doFn: ( key: string, doCallback: () => void, skipCallback?: () => void ) => void = doOncePerVisit;
			if ( popupResponse.preview ) {
				doFn = ( key: string, doCallback: () => void ): void => {
					doCallback();
				};
			}

			doFn( popupResponse.id, () => {
				// Only open popup when no other overlays are opened.
				if ( document.body.classList.contains( 'is-showing-modal-dialog' ) ) {
					return;
				}

				this.renderContent( popup_content, popupResponse );

				setTimeout( () => {
					// Only open popup when no other overlays are opened.
					if ( document.body.classList.contains( 'is-showing-modal-dialog' ) ) {
						return;
					}

					this.updateState( 'open' );
				}, 1000 );
			} );
		}, 4000 );
	}

	override async willOpen(): Promise<void> {
		document.body.classList.add( 'is-showing-modal-dialog' );

		await super.willOpen();
	}

	override async willClose(): Promise<void> {
		document.body.classList.remove( 'is-showing-modal-dialog' );

		await super.willClose();
	}

	override openAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.popup__inner' ),
				[
					{
						opacity: 0,
						transform: 'scale(1.1)',
					},
					{
						opacity: 1,
						transform: 'scale(1)',
					},
				],
				{
					delay: 192,
					duration: 384,
					easing: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.popup__inner' ),
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 128,
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}

	private renderContent( popup_content: Element, popupResponse: { html: string } ) {
		if ( this.#hasRendered ) {
			return;
		}

		// add content
		popup_content.innerHTML = popupResponse.html;
		this.#hasRendered = true;
	}
}

customElements.define( 'mr-popup', MrPopup );
