import { MrAccordion } from '@mrhenry/wp--mr-interactive';

class ServicePriceListAccordion extends MrAccordion {
	constructor() {
		super();
	}

	override handleToggleEvent( trigger: Element, onlyOpen: boolean ): boolean {
		super.handleToggleEvent( trigger, onlyOpen );

		// handle active row
		const parentItem = trigger.closest( '.service-price-list__accordion__item' );
		if ( parentItem?.hasAttribute( 'is-open' ) ) {
			parentItem.removeAttribute( 'is-open' );
		} else {
			parentItem?.setAttribute( 'is-open', '' );
		}

		return true;
	}
}

customElements.define( 'service-price-list-accordion', ServicePriceListAccordion );
