function initBookingRoomsForm() {
	const form = document.querySelector( 'form[booking-rooms-form]' ) as HTMLFormElement | null;
	if ( !form ) {
		return;
	}

	const checkIn = form.querySelector( 'input[check-in-date]' ) as HTMLInputElement | null;
	const checkOut = form.querySelector( 'input[check-out-date]' ) as HTMLInputElement | null;
	if ( !checkIn || !checkOut ) {
		return;
	}

	const now = new Date( Date.now() );

	checkIn.setAttribute( 'min', formatDate( now ) );
	checkOut.setAttribute( 'min', formatDate( now, 1 ) );

	checkIn.removeEventListener( 'change', changeHandler );
	checkIn.addEventListener( 'change', changeHandler );
}

function changeHandler( event: Event ) {
	const elem = event.target as HTMLInputElement;
	const checkInDate = elem.valueAsDate;
	if ( !checkInDate ) {
		return;
	}

	const checkOut = document.querySelector( 'input[check-out-date]' ) as HTMLInputElement | null;
	if ( !checkOut ) {
		return;
	}

	checkOut.setAttribute( 'min', formatDate( checkInDate, 1 ) );

	const checkOutDate = checkOut.valueAsDate;
	if ( checkOutDate && checkInDate >= checkOutDate ) {
		const tomorrow = new Date( checkInDate );
		tomorrow.setDate( tomorrow.getDate() + 1 );
		checkOut.valueAsDate = tomorrow;
	}
}

function formatDate( inputDate: Date | null, dayOffset = 0 ): string {
	if ( !inputDate ) {
		return '';
	}

	let day = inputDate.getDate();
	const month = inputDate.getMonth() + 1;
	const year = inputDate.getFullYear();

	if ( 0 !== dayOffset ) {
		day = day + dayOffset;
	}

	let dd = day.toString( 10 );
	let mm = month.toString( 10 );

	if ( 10 > day ) {
		dd = '0' + day;
	}

	if ( 10 > month ) {
		mm = '0' + month;
	}

	return year + '-' + mm + '-' + dd;
}


initBookingRoomsForm();
requestAnimationFrame( () => {
	initBookingRoomsForm();
} );

window.addEventListener( 'load', () => {
	initBookingRoomsForm();
} );
